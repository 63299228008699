/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
    .app {
        // border: solid 1px red; // leave it here for debug
        min-height: 100vh;
        height: max-content;
    }

    .send-downloadLink-inputBox:active,
    .send-downloadLink-inputBox:focus {
        outline: none !important;
    }

    .fca-err-tip {
        @include fca-err-tip-shouldShow;
    }
}