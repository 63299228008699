.app {
    height: 100vh;
}

.miles_ui_email_preference_only.bottom_space {
    height: 50px;
    width: 100%;
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 360px) {
    .app {
        height: calc(100vh + 120px);
    }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-device-width : 480px) {
    .app {
        height: calc(100vh + 120px);
    }
}

@media only screen and (min-width : 1441px) {
    .app.web_v2_unsubscribe {
        height: 1400px;
    }
}