/* Hide scrollbar for Chrome, Safari and Opera */
@mixin fca-landing-wrap-webkit-scrollbar {
    display: none;
}
  
/* Hide scrollbar for IE, Edge and Firefox */
@mixin fca-landing-wrap {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

@mixin invisible-scrollbar-webkit-scrollbar {
    display: none;
}

@mixin get-the-app-btn {
    display: none;
}

@mixin fca-landing-wrap {
    width: 620px;
    height: inherit;
    overflow: visible;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

@mixin uconnect-miles-logo {
    width: 344px;
    height: 47px;
    display: block;
    margin: 0 auto 30px;
}

@mixin fca-titile-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@mixin fca-title-line-1 {
    font-family: 'Avenir Next';
    font-size: 25px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #5f5f69;
}

@mixin fca-title-line-2 {
    margin-top: 7px;
    font-family: 'Avenir Next';
    font-size: 25px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #5f5f69;
}

@mixin fca-gift-img {
    width: 633px;
    height: 230px;
    margin-bottom: 50px;
}

@mixin fca-icon-wrap {
    display: flex;
    // padding-left: 75px;
    flex-direction: row;
    justify-content: center;
}

@mixin fca-share-icon {
    display: block;
    width: 34px;
    height: 34px;
}

@mixin fca-share-text {
    height: 40px;
    // margin: 1px 0 0 36px;
    font-family: 'Avenir Next';
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    color: #898989;
    margin-left: 40px;
}

@mixin redeem-wrap {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 15px;
}

@mixin fca-redeem-icon {
    display: block;
    width: 34px;
    height: 34px;
    margin-left: 16px;
}

@mixin fca-redeem-text {
    height: 40px;
    font-family: 'Avenir Next';
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    color: #898989;
    margin-left: 40px;
    margin-bottom: 50px;
}

@mixin invite-code-tiip {
    margin-top: 40px;
    margin-bottom: 8px;
    font-family: 'Avenir Next';
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #00aeef;
}

@mixin copy-code-box {
    height: 52px;
    margin: 0 auto;
    padding: 0;
    border-radius: 30px;
    border: dashed 1.5px #afafb5;
    background-color: #ffffff;
    font-family: 'Avenir Next';
    font-size: 28px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.75px;
    color: #363636;
    box-sizing: border-box;
    outline: none !important;
    text-align: center;
    line-height: 52px;
    vertical-align: middle;
}

@mixin showFcaCopy {
    font-size: 20px;
}

@mixin or-text {
    height: 45px;
    line-height: 45px;
    vertical-align: middle;
    font-family: 'Avenir Next';
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #a2a2a2;
    margin-left: -60px;
}

@mixin send-downloadLink-wrap {

}

@mixin send-downloadLink-inputBox {
    width: 317px;
    height: 62px;
    border-top-right-radius: 31px;
    border-bottom-right-radius: 31px;
    box-shadow: 3px 4px 24px 0 rgba(32, 32, 35, 0.09);
    border: solid 0.1px #363636;
    background-color: #ffffff;
    border-color: transparent;
    margin-left: 70px;
    padding-left: 20px;
    box-sizing: border-box;
    font-family: 'Avenir Next';
    font-size: 22px;
    font-weight: 500;
    outline-style: none;
}

@mixin send-downloadLink-inputBox-webkit-input-placeholder {
    width: 209px;
    height: 30px;
    margin: 16px 0 16px 17px;
    font-family: 'Avenir Next';
    font-size: 22px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 2.24px;
    text-align: left;
    color: #bfbfbf;
}

/* firefox 19+ */
@mixin send-downloadLink-inputBox-moz-placeholder {
    width: 209px;
    height: 30px;
    margin: 16px 0 16px 17px;
    font-family: 'Avenir Next';
    font-size: 22px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 2.24px;
    text-align: left;
    color: #bfbfbf;
    padding-left: 5px;
}

/* ie */
@mixin send-downloadLink-inputBox-ms-input-placeholder {
    width: 209px;
    height: 30px;
    margin: 16px 0 16px 17px;
    font-family: 'Avenir Next';
    font-size: 22px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 2.24px;
    text-align: left;
    color: #bfbfbf;
    padding-left: 5px;
}

@mixin send-downloadLink-inputBox-moz-placeholder {
    width: 209px;
    height: 30px;
    margin: 16px 0 16px 17px;
    font-family: 'Avenir Next';
    font-size: 22px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 2.24px;
    text-align: left;
    color: #bfbfbf;
    padding-left: 5px;
    line-height: 62px;
}

@mixin send-downloadLink-inputBox-pre {
    font-family: 'Avenir Next';
    font-size: 28px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    position: absolute;
    border-top-left-radius: 31px;
    border-bottom-left-radius: 31px;
    box-shadow: 3px 8px 24px 1px rgba(32, 32, 35, 0.19);
    background-color: #00aeef;
    height: 62px !important;
    width: 75px;
    margin-left: -388px;
    box-sizing: border-box;
    text-align: center;
    line-height: 62px;
    vertical-align: middle;
}

@mixin send-downloadLink-btn {
    width: 194px;
    height: 62px;
    margin: 0 0 0 37px;
    border-radius: 31px;
    box-shadow: 3px 8px 24px 1px rgba(32, 32, 35, 0.19);
    background-color: #00aeef;
    border-color: transparent;
    font-family: 'Avenir Next';
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: center;
    line-height: 62px;
    vertical-align: middle;
    color: #ffffff;
    position: absolute;
    cursor: pointer;
    outline: none !important;
}

@mixin download-link-box {
    height: 62px;
    border-radius: 31px;
    box-shadow: 3px 8px 24px 1px rgba(32, 32, 35, 0.19);
    background-color: #00aeef;
    box-sizing: border-box;
    font-family: 'Avenir Next';
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 62px;
    letter-spacing: 0.5px;
    text-align: center;
    color: #ffffff;
    margin: 0;
    padding: 0;
    min-width: 100%;
    vertical-align: middle;
}

@mixin send-downloadLink-inputBox-active-or-focus {
    outline: none !important;
}

@mixin fca-err-tip {
    display: none;
}

@mixin fca-err-tip-shouldShow {
    display: block;
    width: 285px;
    height: 22px;
    margin: 10px 124px 0 0;
    font-family: 'Avenir Next';
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ff4949;
}

@mixin hide {
    display: none;
}

@mixin showFcaCopy {
    font-size: 20px;
}

@mixin get-the-app-btn {
    display: none;
}

@mixin send-downloadLink-inputBox-fcaErr {
    border: solid 1.5px #ff4949;
    border-left: 0;
}

@mixin send-downloadLink-inputBox-pre-fcaErr {
    border: solid 1.5px #ff4949;
    border-right: 0;
    box-sizing: border-box;
    text-align: center;
    line-height: 62px;
    vertical-align: middle;
}