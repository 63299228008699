/* Extra Small Devices, Phones */ 
@media only screen and (min-width : 361px) and (max-width : 768px) {
    /* .fca-landing-wrap {
    } */
    .fca-err-tip {
        display: none;
    }

    .fca-landing-wrap {
        width: inherit;
    }
    
    .uconnect-miles-logo {
        width: 50%;
        height: auto;
        display: block;
    }

    .fca-titile-wrap {
        height: 60px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    
    .fca-title-line-1 {
        font-size: 15px;
    }
    
    .fca-title-line-2 {
        font-size: 15px;
    }
    
    .fca-gift-img {
        width: 85%;
        height: auto;
        margin: 0 auto 30px;
        display: block;
    }

    .fca-icon-wrap {
        .fca-share-icon {
            width: 24px;
            height: 24px;
        }
        
        .fca-share-text {
            font-size: 15px;
            margin-left: 12px;
            height: 24px;
        }
    }

    .redeem-wrap {
        .fca-redeem-icon {
            display: block;
            width: 24px;
            height: 24px;
            margin-left: 10px;
        }
        
        .fca-redeem-text {
            font-size: 15px;
            margin-left: 12px;
            height: 24px;
            margin-bottom: 24px;
        }
    }

    .copy-code-box {
        margin: 5px 20px 5px 20px;
        font-size: 24px;
        text-align: center;
        vertical-align: middle;
    }
    
    .or-text {
        display: none;
    }
    
    .send-downloadLink-wrap {
        display: none;
    }

    .get-the-app-btn {
        display: visible;
        display: block;
        margin: 15px 20px 5px 20px;
        height: 55px;
        padding: 10px;
        color: #ffffff;
        background-color: #00aeef;
        border: 1px solid #00aeef;
        border-radius: 30px;
        font-family: 'Avenir Next';
        font-size: 15px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 35px;
        letter-spacing: 0.5px;
        text-align: center;
        outline: none !important;
        box-shadow: none !important;
        box-sizing: border-box;
        text-decoration: none;
        box-shadow: 3px 8px 24px 1px rgba(32, 32, 35, 0.19) !important;
        outline: none !important;

        :hover {
            text-decoration: none !important;
        }
    }

    a.get-the-app-btn {
        text-decoration: none !important;
    }

    a.get-the-app-btn:hover {
        color: #ffffff;
    }

    .showFcaCopy {
        font-size: 20px;
    }
}