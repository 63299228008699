/* iPhone 6+, 7+, 8+ ----------- */
@media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 2){
/* Styles */
}

@media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) {
/* Styles */
    .fca-share-text {
        font-size: 15px;
    }

    .fca-redeem-text {
        font-size: 15px;
    }
}