@import './common.scss';

.fca-landing-wrap::-webkit-scrollbar {
    @include fca-landing-wrap-webkit-scrollbar;
}

.invisible-scrollbar::-webkit-scrollbar {
    @include invisible-scrollbar-webkit-scrollbar;
}

.get-the-app-btn {
    @include get-the-app-btn;
}

.fca-landing-wrap {
    @include fca-landing-wrap;
}

.fca-titile-wrap {
    @include fca-titile-wrap;
}

.uconnect-miles-logo {
    @include uconnect-miles-logo;
}

.fca-title-line-1 {
    @include fca-title-line-1;
}

.fca-title-line-2 {
    @include fca-title-line-2;
}

.fca-gift-img {
    @include fca-gift-img;
}

.fca-icon-wrap {
    @include fca-icon-wrap;
}

.fca-share-icon {
    @include fca-share-icon;
}

.fca-share-text {
    @include fca-share-text;
}

.redeem-wrap {
    @include redeem-wrap;
}

.fca-redeem-icon {
    @include fca-redeem-icon;
}

.fca-redeem-text {
    @include fca-redeem-text;
}

.copy-code-box {
    @include copy-code-box;
}

.showFcaCopy {
    @include showFcaCopy;
}

.or-text {
    @include or-text;
}

.send-downloadLink-wrap {
    @include send-downloadLink-wrap;
}

.send-downloadLink-inputBox {
    @include send-downloadLink-inputBox;
}

.send-downloadLink-inputBox::-webkit-input-placeholder {
    @include send-downloadLink-inputBox-webkit-input-placeholder;
}

.send-downloadLink-inputBox::-moz-placeholder {
    @include send-downloadLink-inputBox-moz-placeholder;
}

.send-downloadLink-inputBox:-ms-input-placeholder {
    @include send-downloadLink-inputBox-ms-input-placeholder
}

.send-downloadLink-inputBox:-moz-placeholder {
    @include send-downloadLink-inputBox-moz-placeholder;
}

.send-downloadLink-inputBox-pre {
    @include send-downloadLink-inputBox-pre;
}

.send-downloadLink-btn {
    @include send-downloadLink-btn;
}

.download-link-box {
    @include download-link-box;
}

.send-downloadLink-inputBox:active,
.send-downloadLink-inputBox:focus {
    @include send-downloadLink-inputBox-active-or-focus
}

.fca-err-tip {
    @include fca-err-tip;
}

.hide {
    @include hide;
}

.showFcaCopy {
    @include showFcaCopy;
}

.get-the-app-btn {
    @include get-the-app-btn;
}

.send-downloadLink-inputBox.fcaErr {
    @include send-downloadLink-inputBox-fcaErr;
}

.send-downloadLink-inputBox-pre.fcaErr {
    @include send-downloadLink-inputBox-pre-fcaErr;
}