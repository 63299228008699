/* iPhone 5 ----------- */
@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 2){
/* Styles */
}

@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
    .app {
        height: calc(100vh - 60px);
        // border: solid 1px red; // leave here for debug
    }

    .uconnect-miles-logo {
        width: 60%;
        margin: 50px auto 35px;
    }
    
    .fca-icon-wrap {
        display: flex;
    }

    .fca-icon-wrap {
        .fca-share-icon {
            width: 20px;
            height: 20px;
        }
        .fca-share-text {
            font-size: 13px;
        }
    }
    
    .redeem-wrap {
        .fca-redeem-icon {
            width: 20px;
            height: 20px;
        }
        .fca-redeem-text {
            font-size: 13px;
        }
    }
    
    .copy-code-box {
        margin: 5px 10px 5px 10px;
        padding: 2px;
        font-size: 18px;
        text-align: center;
    }
    
    .get-the-app-btn {
        width: 93%;
        margin: 15px 10px 5px 10px;
        :hover {
            text-decoration: none !important;
        }
    }
}