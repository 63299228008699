/* Small Devices, Tablets */
@media only screen and (min-width : 768px) and (max-width : 992px) {
    .app {
        // border: solid 1px yellow; // leave it here for debug
        min-height: 100vh;
        height: max-content;
    }

    .fca-err-tip {
        @include fca-err-tip-shouldShow;
    }
    
    .copy-code-box.showFcaCopy {
        font-size: 20px;
    }
}