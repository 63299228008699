/* iPhone 6, 7, 8 ----------- */
@media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 2){
}

@media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
    .fca-title-line-1 {
        font-size: 15px;
    }
    
    .fca-title-line-2 {
        font-size: 15px;
    }
    
    .fca-share-text {
        font-size: 15px;
    }
    
    .fca-redeem-text {
        font-size: 15px;
    }

}