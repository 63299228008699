/* space divider start */
.miles_ds_height_6 {
    height: 256px;
}

.miles_ds_height_4 {
    height: 64px;
}

/* space divider end*/

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 360px) {
    /* space divider start */
    .miles_ds_height_6 {
        height: 128px;
    }

    .miles_ds_height_4 {
        height: 32px;
    }
    /* space divider end*/
}

/* Extra Small Devices, Phones */ 
@media only screen and (min-width : 361px) and (max-width : 768px) {
    /* space divider start */
    .miles_ds_height_6 {
        height: 128px;
    }

    .miles_ds_height_4 {
        height: 32px;
    }
    /* space divider end*/
}

/* ----------- Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width : 361px) and (max-device-width : 480px) {
}

/* iPhone 6, 7, 8 ----------- */
@media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
}

/* iPhone 6+, 7+, 8+ ----------- */
@media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
}

/* Small Devices, Tablets ----------- */
@media only screen and (min-width : 769px) and (max-width : 1150px) {
    /* space divider start */
    .miles_ds_height_6 {
        height: 128px;
    }

    .miles_ds_height_4 {
        height: 32px;
    }
    /* space divider end*/
}

/* ----------- desktop ----------- */
/* Medium Devices, Desktops ----------- */
@media only screen and (min-width : 1151px) and (max-width : 1439px) {
  
}

/* Large Devices, Wide Screens ----------- */
@media only screen and (width : 1440px) {
}

/* Large Devices, Wide Screens ----------- */
@media only screen and (min-width : 1441px) {

}